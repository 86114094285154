body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-family: Open Sans;@btn-font-weight: 600;@typography-title-font-weight: 700;@primary-color: #52c41a;@link-color: #1890ff;@gray-1: #ffffff;@gray-2: #fafafa;@gray-3: #f5f5f5;@gray-4: #f0f0f0;@gray-5: #d9d9d9;@gray-6: #bfbfbf;@gray-7: #8c8c8c;@gray-8: #595959;@gray-9: #434343;@gray-10: #262626;